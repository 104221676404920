import React, { useState } from 'react';
import './LegalTeamsIndex.css';
import LegalTeamMemberCard from './LegalTeamMemberCard/LegalTeamMemberCard';
import { legalTeamMembersData } from './legalTeamData';
import addIcon from '@icons/add-white-icon.svg';
import searchIcon from '@icons/search-gray-icon.svg';
import Dropdown from '@Shared/Dropdown/Dropdown';
import Drawer from '../../../../../Shared/Drawer/Drawer';
import AddNewMember from './AddNewMember/AddNewMember';

const roles = ['All Members', 'Admin', 'Members'];

const LegalTeamsIndex = () => {
  const [selectedRole, setSelectedRole] = useState(roles[0]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [teamMembers, setTeamMembers] = useState(
    legalTeamMembersData.map((member) => ({
      ...member,
      isAdded: true,
    }))
  );

  const handleToggleMember = (id) => {
    setTeamMembers((prevMembers) =>
      prevMembers.map((member) =>
        member.id === id ? { ...member, isAdded: !member.isAdded } : member
      )
    );
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  }

  return (
    <div className="dashboard-legal-team-container">
      <div className="legal-team-dashboard-sub-header-container">
        <h1 className="dashboard-legal-team-heading">
          Federal Criminal{' '}
          <span className="dashboard-legal-team-heading-gray">
            Legal Team ({legalTeamMembersData?.length} members)
          </span>{' '}
        </h1>
        <div>
          <div className="search-container">
            <div className="search-input-container">
              <img src={searchIcon} alt="Search Icon" className="search-icon" />
              <input
                type="text"
                placeholder="Search member..."
                className="search-input"
              />
            </div>
            <Dropdown
              options={roles}
              selectedOption={selectedRole}
              onOptionSelect={handleRoleSelect}
              placeholder="Select Role"
              isFilter={true}
            />
          </div>
        </div>
      </div>
      <div className="legal-team-member-cards-container">
        {legalTeamMembersData?.map((data, index) => (
          <LegalTeamMemberCard
            key={index}
            image={data.image}
            name={data.name}
            role={data.role}
            title={data.title}
            id={data.id}
          />
        ))}
        <div className="legal-add-team-member-card" onClick={toggleDrawer}>
          <img src={addIcon} alt="" />
          <p className="add-team-member-card-text">Add Team Member</p>
        </div>
      </div>
      
      <Drawer title={'Select Team Members'} isOpen={openDrawer} onClose={toggleDrawer}>
        <AddNewMember teamMembers={teamMembers} onToggleMember={handleToggleMember}/>
      </Drawer>
    </div>
  );
};

export default LegalTeamsIndex;
