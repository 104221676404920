import React, { useContext, useState } from 'react';
import GenerateMotionContext from './GenerateMotionContext';
import { groupedMotionOptions } from '@lib/utils/motionDictionary';
import dropdownIcon from '@assets/images/atticus/icons/arrow-dropdown.svg';

const MotionOptionsSelect = () => {
  const { selectedMotion, setSelectedMotion } = useContext(GenerateMotionContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);

  const handleCategoryClick = (category) => {
    setActiveCategory((prev) => (prev === category ? null : category));
  };

  const handleMotionSelect = (option) => {
    setSelectedMotion(option);
    setIsDropdownOpen(false);
    setActiveCategory(null);
  };

  return (
    <div className="motion-options-dropdown-container">
      <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="motion-options-select-button">
        {selectedMotion?.name || 'Select Other Motions'}
        <img src={dropdownIcon} alt="Dropdown Arrow" className={`dropdown-arrow ${isDropdownOpen ? 'open' : ''}`} />
      </button>

      {isDropdownOpen && (
        <div className="motion-options-dropdown">
          <div className="motion-options-flex">
            <div className="motion-options-category-column">
              {groupedMotionOptions.map((group) => (
                <div key={group.category} className="motion-category-group">
                  <button
                    onClick={() => handleCategoryClick(group.category)}
                    className={`motion-options-category-button`}
                  >
                    {group.category}
                    <img
                      src={dropdownIcon}
                      alt="Dropdown Arrow"
                      className={`category-dropdown-arrow ${activeCategory === group.category ? 'open' : ''}`}
                    />
                  </button>
                  <hr className="border-bottom-motion-category" />
                  {activeCategory === group.category && (
                    <div className="motion-options-options-container">
                      {group.options.map((option) => (
                        <div
                          key={option.value}
                          onClick={() => handleMotionSelect(option)}
                          className="motion-options-option-list"
                        >
                          <span className="motion-option-bullet">•</span>
                          <span className="motion-option-text">{option.name}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MotionOptionsSelect;
