import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import './GenerateMotionModal.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MotionModalPage1 from './GenerateMotionModalPages/MotionModalPage1';
import MotionModalPage2 from './GenerateMotionModalPages/MotionModalPage2';
import MotionModalPage3 from './GenerateMotionModalPages/MotionModalPage3';
import MotionModalPage4 from './GenerateMotionModalPages/MotionModalPage4';
import GenerateMotionContext from '../GenerateMotionContext';
import GenerateMotionLoader from './GenerateMotionModalPages/GenerateMotionLoader/GenerateMotionLoader';
import ConfirmationModal from '@Shared/ConfirmationModal/ConfirmationModal';

const GenerateMotionModal = () => {
  const { showModal, setShowModal, closeModal, currentPageIndex, setCurrentPageIndex, error, setError, loading, setLoading } = useContext(GenerateMotionContext);
  const caseId = useParams().id;
  const user = useSelector((state) => state.user);

  const handleClose = () => closeModal();
  const [documentName, setDocumentName] = useState(null);
  const [motionId, setMotionId] = useState(null);
  const [motionType, setMotionType] = useState(null);
  const [citations, setCitations] = useState([]);
  const [processedCitations, setProcessedCitations] = useState(null);
  const [step, setStep] = useState(0);
  const [output, setOutput] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [blob, setBlob] = useState(null);
  const [currentApi, setCurrentApi] = useState(null);

  const handleShow = () => {
    setError(false);
    setCurrentPageIndex(0);
    setShowModal(true);
  }

  const advancePage = (newPageIndex = 0) => {
    if (newPageIndex) {
      setCurrentPageIndex(newPageIndex);
      return;
    }
  };

  const handleRetry = () => {
    setError(false);
    setStep(step + 1);
    handleShow();
    setLoading(true);
    if (currentApi) {
      currentApi();
    }
  };

  const handleCancel = () => {
    setError(false);
    setLoading(false);
    setCurrentPageIndex(0);
    closeModal();
  };

  return (
    <div>
      {showModal && (
        <div className="generate-motion-modal-overlay">
          {!error ? (
            <Modal show={handleShow} onHide={handleClose} className="generate-motion-modal" backdrop="static" keyboard={false}>
              {loading && <GenerateMotionLoader pageIndex={2} step={step} />}
              {!loading && !error && (
                <MotionModalPage1
                  handleClose={handleClose}
                  currentPageIndex={currentPageIndex}
                  pageIndex={0}
                  advancePage={advancePage}
                  user={user}
                  caseId={caseId}
                  setDocumentName={setDocumentName}
                  setMotionId={setMotionId}
                  citations={citations}
                  setCitations={setCitations}
                  setLoading={setLoading}
                  onError={(isError) => setError(isError)}
                  setCurrentApi={setCurrentApi}
                />
              )}
              <MotionModalPage2
                handleClose={handleClose}
                currentPageIndex={currentPageIndex}
                pageIndex={1}
                advancePage={advancePage}
                setCurrentPageIndex={setCurrentPageIndex}
                citations={citations}
                user={user}
                caseId={caseId}
                documentName={documentName}
                motionId={motionId}
                setDownloadUrl={setDownloadUrl}
                setLoading={setLoading}
              />
              <MotionModalPage4
                handleClose={handleClose}
                currentPageIndex={currentPageIndex}
                pageIndex={3}
                advancePage={advancePage}
                downloadUrl={downloadUrl}
                setDownloadUrl={setDownloadUrl}
                setBlob={setBlob}
                documentName={documentName}
                caseId={caseId}
              />
            </Modal>
          ) : (
            <ConfirmationModal
              isOpen={() => handleShow()} 
              onClose={handleCancel}
              heading="AtticusAI"
              cancelText="Cancel"
              successText="Try Again"
              onSuccess={handleRetry}
              className="confirmation--modal generation-motion-confirmation-modal"
            >
              <p>
                Sorry, We are unable to process your request. Please try again.
              </p>
            </ConfirmationModal>
          )}
        </div>
      )}
    </div>
  );
};

export default GenerateMotionModal;
