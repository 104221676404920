import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { ReactComponent as Funnel } from '@icons/funnel.svg';
import { ReactComponent as DropdownArrowIcon } from '@icons/arrow-dropdown.svg';
import './CaseSearchFilter.css';
import { useLocation } from 'react-router-dom';

const CaseSearchFilter = ({ onFilterChange }) => {
  const dropdownOptions = [
    { text: 'All Cases', filter: 'all' },
    { text: 'Open Cases', filter: 'open' },
    { text: 'Closed Cases', filter: 'closed' },
    { text: 'Active Cases', filter: 'active' },
    { text: 'Inactive Cases', filter: 'inactive' },
  ];

  const location = useLocation();
  const [filter, setFilter] = useState(dropdownOptions[0]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filterParam = queryParams.get('filter');
    if (filterParam) {
      const selectedOption = dropdownOptions.find((option) => option.filter === filterParam);
      if (selectedOption) {
        setFilter(selectedOption);
        onFilterChange(selectedOption.filter);
      }
    }
  }, [location.search, onFilterChange]);

  const filterSearch = (filterObject) => {
    setFilter(filterObject);
    onFilterChange(filterObject.filter);
  };

  return (
    <Dropdown className="case-search-filter-dropdown">
      <Dropdown.Toggle variant="" id="cases-table-filter-dropdown">
        <Funnel />
        <p className="cases-dropdown-toggle-text">{filter.text}</p>
        <DropdownArrowIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {dropdownOptions.map((option, index) => (
          <Dropdown.Item key={index}>
            <button className="cases-header-dropdown-option" onClick={() => filterSearch(option)}>
              {option.text}
            </button>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CaseSearchFilter;
