import React, { useState, useEffect } from 'react';
import './ProcessingMessageBubble.css';

const chatLoadingMessages = [
  'Analayzing request',
  'Checking case context',
  'Researching the law',
  'Formulating Arguments',
]

const ProcessingMessageBubble = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const messageInterval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => 
        prevIndex < chatLoadingMessages?.length - 1 ? prevIndex + 1 : 0
      )
    }, 30000)
    return () => clearInterval(messageInterval);
  }, []);

  return (
    <>
      <div class="chat-loader-container">
        <div class="chat-loader-section">
          <div class="chat-loader inner-one"></div>
          <div class="chat-loader inner-two"></div>
          <div class="chat-loader inner-three"></div>
        </div>

        <span class="searching-the-web-text">{chatLoadingMessages[currentMessageIndex]}</span>
        <div className="processing-message-bubble">
          <div class="processing-message-dot"></div>
          <div class="processing-message-dot"></div>
          <div class="processing-message-dot"></div>
        </div>
      </div>
    </>
  );
};

export default ProcessingMessageBubble;
