import omerObaid from "./../../../../../../assets/images/atticus/team/omer-obaid.png";
import engineerFive from "./../../../../../../assets/images/atticus/team/engineer-five.png";
import engineerSeven from "./../../../../../../assets/images/atticus/team/engineer-seven.png";
import engineerEight from "./../../../../../../assets/images/atticus/team/engineer-eight.png";
import engineerNine from "./../../../../../../assets/images/atticus/team/engineer-nine.png";
import mel from "./../../../../../../assets/images/atticus/team/mel.png";



 export const legalTeamImages = [omerObaid, mel, engineerFive, engineerSeven, engineerEight, engineerNine];

export const legalTeamMembersData = [
  {
    image: engineerFive,
    name: 'Floyd Miles',
    email: "floydmiles@gmail.com",
    officeName: "Equity Law Associates",
    role: 'Admin',
    title: 'Paralegal',
    id: '123',
    assignedCases: [
      { id: "12345", caseName: "Case Name 1", date: "10-12-2024", status: "O" },
      { id: "12346", caseName: "Case Name 2", date: "11-12-2024", status: "P" },
      { id: "12347", caseName: "Case Name 3", date: "12-12-2024", status: "C" },
      { id: "12348", caseName: "Case Name 4", date: "13-12-2024", status: "O" }
    ]

  },
  {
    image: engineerSeven,
    email: "floydmiles@gmail.com", officeName: "Equity Law Associates",
    name: 'Diane Russel',
    role: 'Member',
    title: 'Sr. Attorney',
    id: '124',
    assignedCases: [
      { id: "12345", caseName: "Case Name 1", date: "10-12-2024", status: "O" },
      { id: "12346", caseName: "Case Name 2", date: "11-12-2024", status: "P" },
      { id: "12347", caseName: "Case Name 3", date: "12-12-2024", status: "C" },
      { id: "12348", caseName: "Case Name 4", date: "13-12-2024", status: "O" }
    ]
  },
  {
    image: engineerEight,
    email: "floydmiles@gmail.com", officeName: "Equity Law Associates",
    name: 'Wade Warren',
    role: 'Member',
    title: 'Jr. Attorney',
    id: '125',
    assignedCases: [
      { id: "12345", caseName: "Case Name 1", date: "10-12-2024", status: "O" },
      { id: "12346", caseName: "Case Name 2", date: "11-12-2024", status: "P" },
      { id: "12347", caseName: "Case Name 3", date: "12-12-2024", status: "C" },
      { id: "12348", caseName: "Case Name 4", date: "13-12-2024", status: "O" }
    ]
  },
  {
    image: engineerNine,
    name: 'Brooklyn Simmons',
    email: "floydmiles@gmail.com", officeName: "Equity Law Associates",
    role: 'Admin',
    title: 'Sr. Attorney',
    id: '126'
    , assignedCases: [
      { id: "12345", caseName: "Case Name 1", date: "10-12-2024", status: "O" },
      { id: "12346", caseName: "Case Name 2", date: "11-12-2024", status: "P" },
      { id: "12347", caseName: "Case Name 3", date: "12-12-2024", status: "C" },
      { id: "12348", caseName: "Case Name 4", date: "13-12-2024", status: "O" }
    ]
  },

  {
    image: omerObaid,
    name: 'Omer Obaid',
    email: "floydmiles@gmail.com", officeName: "Equity Law Associates",
    role: 'member',
    title: 'Paralegal',
    id: '127',
    assignedCases: [
      { id: "12345", caseName: "Case Name 1", date: "10-12-2024", status: "O" },
      { id: "12346", caseName: "Case Name 2", date: "11-12-2024", status: "P" },
      { id: "12347", caseName: "Case Name 3", date: "12-12-2024", status: "C" },
      { id: "12348", caseName: "Case Name 4", date: "13-12-2024", status: "O" }
    ]
  },
  {
    image: mel,
    name: 'Ronald Richards',
    email: "floydmiles@gmail.com", officeName: "Equity Law Associates",
    role: 'member',
    title: 'Paralegal',
    id: '127',
    assignedCases: [
      { id: "12345", caseName: "Case Name 1", date: "10-12-2024", status: "O" },
      { id: "12346", caseName: "Case Name 2", date: "11-12-2024", status: "P" },
      { id: "12347", caseName: "Case Name 3", date: "12-12-2024", status: "C" },
      { id: "12348", caseName: "Case Name 4", date: "13-12-2024", status: "O" }
    ]
  },
];
