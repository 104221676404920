import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DashboardPannel from './DashboardPannel/DashboardPannel';
import PageTitle from '../Shared/PageTitle/PageTitle';
import CasesPannel from './DashboardPannel/CasesPannel';
import SearchSection from './SearchSection/SearchSection';
import './Atticus.css';
import { getClosedCases, getOpenCases } from '@lib/apis/resources/case';
import Loader from '@Shared/Loader/Loader';
import { getUser } from '@lib/apis/resources/user';
import { getLegalTeamMembers } from '@lib/apis/resources/legalTeam';
import GroupAvatar from '@Shared/GroupAvatar/GroupAvatar';
import { legalTeamImages } from '../LegalTeams/LegalTeamsIndex/legalTeamData';
import { toast } from 'react-toastify';

const Atticus = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const NUMBER_OF_CASES_TO_DISPLAY = 2;

  const [closeCases, setCloseCases] = useState([]);
  const [openCases, setOpenCases] = useState([]);
  const [legalTeam, setLegalTeam] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user) {
          console.log('go to login to set user');
          return;
        }

        const email = user?.email;
        const userId = user?.cognito_user_id;
        const token = auth?.access;

        const getUserResponse = await getUser(email);
        const legalTeamId = getUserResponse?.data?.user?.legalTeamIds[0];

          if(!legalTeamId) {
            return;
          }

        const fetchLegalTeam = async () => {
          try {
            const response = await getLegalTeamMembers(legalTeamId);
            const legalTeam = response?.data?.legalTeam?.userIds || [];
            return legalTeam;
          } catch (err) {
            console.error('Error fetching legal team:', err);
            return [];
          }
        };

        const fetchClosedCases = async () => {
          try {
            const response = await getClosedCases(userId, token);
            const closedCases = response?.data?.closedCases || [];
            return closedCases;
          } catch (err) {
            console.error('Error fetching closed cases:', err);
            return [];
          }
        };

        const fetchOpenCases = async () => {
          try {
            const response = await getOpenCases(userId, token);
            const openCases = response?.data?.openCases || [];
            return openCases;
          } catch (err) {
            console.error('Error fetching open cases:', err);
            return [];
          }
        };

        const [legalTeamData, closedCasesData, openCasesData] = await Promise.all([
          fetchLegalTeam(),
          fetchClosedCases(),
          fetchOpenCases(),
        ]);

        setLegalTeam(legalTeamData);
        setCloseCases(closedCasesData)
        setOpenCases(openCasesData);
      } catch (error) {
        console.error('Error getting cases and legal team:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, dispatch]);

  const pannelData = [
    {
      title: 'Your legal team',
      subtitle: '10 members',
      link: 'Manage Team',
    },
    {
      title: 'Open cases',
      subtitle: '5 cases',
      link: 'See Cases',
    },
    {
      title: 'Closed cases',
      subtitle: '10 cases',
      link: 'See Cases',
    },
  ];

  const userInfo = [
    { src: 'https://picsum.photos/200', alt: 'User headshot', link: 'team' },
    { src: 'https://picsum.photos/100', alt: 'User headshot', link: 'team' },
    { src: 'https://picsum.photos/200', alt: 'User headshot', link: 'team' },
    { src: 'https://picsum.photos/100', alt: 'User headshot', link: 'team' },
  ]; 

  

  if (loading) {
    return <Loader/>;
  }

  const legalTeamMembers = legalTeam?.map((userId, index) => {
    return {
      user_id: userId,
      image: legalTeamImages[index % legalTeamImages.length], 
    };
  });

  return (
    <main className="dashboard-page main-page">
      <PageTitle title="Federal Criminal" subtitle="Overview" />
      <div className="dashboard-pannel-container">
        <DashboardPannel title="Your Legal Team" subtitle={`${legalTeam?.length > 0 ? legalTeam?.length : 0} members`} linkText="Manage Team" linkAnchor="team">
          <GroupAvatar legalTeam={legalTeamMembers}/>
        </DashboardPannel>
        <CasesPannel showingOpenCases={true} cases={openCases} numberOfCasesToDisplay={NUMBER_OF_CASES_TO_DISPLAY} />
        <CasesPannel showingOpenCases={false} cases={closeCases} numberOfCasesToDisplay={NUMBER_OF_CASES_TO_DISPLAY} />
      </div>
      <div className="dashboard-home-search">
        <h2>Search Cases</h2>
        <SearchSection />
      </div>
    </main>
  );
};

export default Atticus;
