import React from 'react';
import './AddNewMember.css';
import deleteIcon from '@assets/images/atticus/icons/delete-green-icon.svg';
import Button from '@Shared/Button/Button';

const AddNewMember = ({ teamMembers, onToggleMember }) => {
  return (
    <div className="add-new-member-container">
      {teamMembers.map((member) => (
        <div key={member.id} className="team-member-card">
          <div className="member-info">
            <img src={member.image} alt={member.name} className="member-avatar" />
            <div className='team-member-card-content'>
              <p className="member-name">{member.name}</p>
              <p className="member-role">{member.title}</p>
            </div>
          </div>
          <div className="member-actions">
            {member.isAdded ? (
              <>
                <Button className="added-button common-button" disabled>
                  Added
                </Button>
                <img
                  src={deleteIcon}
                  alt="Delete Icon"
                  className="delete-icon"
                  onClick={() => onToggleMember(member.id)}
                />
              </>
            ) : (
              <Button
                className="button--primary add-button"
                onClick={() => onToggleMember(member.id)}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddNewMember;
