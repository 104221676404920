import axios from "axios";
import { parseSSE } from '@lib/apis/utilities';
// const URL = 'https://api-dev.juristai.org/chat/api/ask/openAI/';

const DEFAULT_VALUES = {
  conversationId: "00000000-0000-0000-0000-000000000000",
  parentMessageId: "00000000-0000-0000-0000-000000000000"
}
const ASSISTANT_ID = "asst_AmtxoqkNiXqZRXT9woPvYPfK";

const getHeaders = (token) => {
  return {
    "Authorization": `Bearer ${token}`,
    "Content-Type": "application/json",
  }
}

// Generates a random conversationId
function generateConversationId(userId, caseId, threadId) {
  const defaultThreadId = "00000000-0000-0000-0000-000000000000";
  const randomString = Array(8)
    .fill(null)
    .map(() => Math.random().toString(36).charAt(2)) // Generates a random alphanumeric character
    .join('');

  // Use the default if threadId is null or undefined
  const thread = threadId ?? defaultThreadId;

  return `userId:${userId}|caseId:${caseId}|threadId:${thread}|customId:${randomString}`;
}

const nameNewConversation = async (conversationId, newName, token) => {
  const URL = 'http://librechat-alb-1920668103.us-east-1.elb.amazonaws.com/api/convos/update';
  const body = {
    "arg": {
      "conversationId": conversationId,
      "title": newName
    }
  }
  const response = await axios.post(URL, body, { headers: getHeaders(token) });
  console.log("nameNewConversation response", response)
}

// Sends message to convo, if no conversationId is provided, it will create a new conversation and give it a name
export const sendMessage = async (params, token) => {
  // const URL = 'http://librechat-alb-1920668103.us-east-1.elb.amazonaws.com/api/ask/openAI';
  const URL = "http://librechat-alb-1920668103.us-east-1.elb.amazonaws.com/api/assistants/v1/chat";
  try {
    const conversationId = params.conversationId || generateConversationId(params.userId, params.caseId, params.threadId);

    const body = {
      "text": params.input,
      "model": "gpt-4o",
      "endpoint": "assistants",
      "assistant_id": ASSISTANT_ID,
      "thread_id": params.threadId || "00000000-0000-0000-0000-000000000000",
      "conversationId": conversationId,
      "parentMessageId": params.parentMessageId || DEFAULT_VALUES.parentMessageId,
    }


    const { data } = await axios.post(URL, body, { headers: getHeaders(token) });  
    const parsedData = parseSSE(data);

    if (!params.conversationId) {
      await nameNewConversation(conversationId, params.input, token);
    }

    return {
      success: true,
      data: parsedData
    }
  } catch (error) {
    console.log('error', error);
    return {
      success: false,
      error
    }
  }
}

export const getConversations = async (token) => {
  const URL = 'http://librechat-alb-1920668103.us-east-1.elb.amazonaws.com/api/convos/';
  try {
    const response = await axios.get(URL, { headers: getHeaders(token) });
    return {
      success: true,
      data: response.data
    }
  } catch (error) {
    console.log('error', error);
    return {
      success: false,
      error
    }
  }
}


export const getMesssagesForConversation = async (conversationId, token) => {
  const URL = `http://librechat-alb-1920668103.us-east-1.elb.amazonaws.com/api/messages/${conversationId}`;
  try {
    const response = await axios.get(URL, { headers: getHeaders(token) });
    console.log("Get Messages fonr conversation response", response)
    return {
      success: true,
      data: response.data
    }
  } catch (error) {
    console.log('error', error);
    return {
      success: false,
      error
    }
  }
}
