import React, { useContext } from 'react';
import Button from '@Shared/Button/Button';
import GenerateMotionContext from './GenerateMotionContext';

const GenerateMotionButton = () => {
  const { setShowModal, selectedMotion, setCurrentPageIndex, setError, setFormData } = useContext(GenerateMotionContext);

  const handleClick = () => {
    if (selectedMotion) {
      setError(false); 
      setCurrentPageIndex(0);
      setFormData({ query: '' });
      setShowModal(true)
    }
  }

  return (
    <Button
      onClick={handleClick}
      className="generate-recommendation-button"
      disabled={selectedMotion !== null ? false : true}
    >
      Generate Motion
    </Button>
  );
};

export default GenerateMotionButton;
