import React from 'react';
import DashboardPannel from './DashboardPannel';
import BadgeInfo from '@Shared/BadgeInfo/BadgeInfo';
import { titleToAbbreviation } from '@lib/utils/caseHelpers';
import { Link } from 'react-router-dom';

const CasesPannel = ({ cases, numberOfCasesToDisplay, showingOpenCases }) => {
  return (
    <DashboardPannel
     title={`${showingOpenCases ? 'Open' : 'Closed'} Cases`}
     subtitle={`${cases?.length > 0 ? cases?.length : 0} cases`}
     linkText="See Cases"
     linkAnchor={`cases?filter=${showingOpenCases ? 'open' : 'closed'}`}
    >
      <div className="dashboard-pannel-badge-container">
        {cases && cases?.map((caseItem, index) => {
          if (index < 2) {
            return (
              <Link key={caseItem.id} to={'#'}>
                <BadgeInfo key={caseItem.id} className={`${showingOpenCases === true ? 'opened' : 'closed'}`}>{titleToAbbreviation(caseItem.caseName)}</BadgeInfo>
              </Link>
            )
          } else if (index === numberOfCasesToDisplay) {
            const remainingCases = cases.length - numberOfCasesToDisplay;
            return <BadgeInfo key={caseItem.id} hasAdditionalItems={true}>+{remainingCases}</BadgeInfo>; // Render a different element for the third item
          } else {
            return null; // Do not render anything for indices greater than 2
          }
        })}


      </div>
    </DashboardPannel>
  );
}

export default CasesPannel;
